import React from 'react';
import { AppProvider } from './src/components/AppContext';

import './src/styles/spectre.min.css'
import './src/styles/spectre-exp.min.css'
import './src/styles/spectre-icons.min.css'

const wrapRootElement = ({element}) => (
  <AppProvider>{element}</AppProvider>
)

export {wrapRootElement};