// @ts-check
import axios from 'axios';
import { isUnauthorized, logoutOn401 } from './auth';
import { adminApiSafeSearchList } from './adminApi';

const SERVER_URL = process.env.GATSBY_API_URL || '/api/v1';
const PROFILE_ENDPOINT = `${SERVER_URL}/user`;
const DOMAIN_ENDPOINT = `${SERVER_URL}/domain`;
const CHILD_ENDPOINT = `${SERVER_URL}/child`;
const CHILD_USER_ENDPOINT = `${SERVER_URL}/child/user`;
const RESET_PASSWORD_ENDPOINT = `${SERVER_URL}/resetpassword`;
const DYNDNS_ENDPOINT = `${SERVER_URL}/dyndns`;
const SAFESEARCH_ENDPOINT = `${SERVER_URL}/safesearch`;
const PORTAL_ENDPOINT = `${SERVER_URL}/portal`;
const LOGFILE_ENDPOINT = `${SERVER_URL}/logfile`;
const USERQUOTA_ENDPOINT = `${SERVER_URL}/userquotaperday`;


// const invalidCredentialsHandler = () => {
//   console.log('invalid credentials');
// };

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // do nothing in case it was a success
    return response;
  },
  function(error) {
    const handled = logoutOn401(error.response.status);
    if (!handled) {
      return Promise.reject(error);
    }
  },
);

/**
 * Get the user profile
 *
 * @param {string} username
 * @param {string} token
 */
const apiFetchProfile = async (username, token) => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${PROFILE_ENDPOINT}/${username}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {object} updatedProfile
 */
const apiUpdateProfile = async (token, updatedProfile) => {
  try {
    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      data: updatedProfile,
      headers: {
        token: token,
      },
      url: `${PROFILE_ENDPOINT}/${updatedProfile.id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 * @param {string} token
 * @param {string} list
 */
const apiFetchDomains = async (token, list = undefined) => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      params: list ? { [list]: true } : {},
      headers: {
        token: token,
      },
      url: DOMAIN_ENDPOINT,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    if (isUnauthorized(error)) {
      console.log('nope');
    }

    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {{domain: string, block: boolean}} domainObj
 * @returns {Promise<any>}
 */
const apiAddDomain = async (token, domainObj) => {
  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      data: domainObj,
      headers: {
        token: token,
      },
      url: DOMAIN_ENDPOINT,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {string} id
 * @returns {Promise<any>}
 */
const apiRemoveDomain = async (token, id) => {
  try {
    let { status, data } = await axios({
      method: 'delete',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${DOMAIN_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {{id: string, domain: string, block: boolean, age:number}} updatedDomain
 */
const apiUpdateDomain = async (token, updatedDomain) => {
  try {
    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      data: updatedDomain,
      headers: {
        token: token,
      },
      url: `${DOMAIN_ENDPOINT}/${updatedDomain.id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 */
const apiFetchChildren = async (token, userId = undefined) => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
        userId: userId
      },
      url: `${userId===undefined ? CHILD_ENDPOINT: CHILD_USER_ENDPOINT+'/'+userId}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {object} childObj
 */
const apiAddChild = async (token, childObj) => {
  console.log(childObj);
  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      data: childObj,
      headers: {
        token: token,
      },
      url: CHILD_ENDPOINT,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {string} id
 */
const apiRemoveChild = async (token, id) => {
  try {
    let { status, data } = await axios({
      method: 'delete',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${CHILD_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 *
 * @param {string} token
 * @param {object} updatedChild
 */
const apiUpdateChild = async (token, updatedChild) => {
  try {
    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      data: updatedChild,
      headers: {
        token: token,
      },
      url: `${CHILD_ENDPOINT}/${updatedChild.id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
};

/**
 *
 * @param {{username: string, email: string , uri: string}} requestData
 * @returns Promise<{message: string}>
 */
const apiResetPasswordRequest = async requestData => {
  try {
    const { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      data: requestData,
      url: `${RESET_PASSWORD_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }

  return undefined;
};

/**
 *
 * @param {{password: string, token: string}} requestData
 */
const apiResetPasswordConfirm = async requestData => {
  try {
    const { token, ...rest } = requestData;

    const { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      data: rest,
      url: `${RESET_PASSWORD_ENDPOINT}/${requestData.token}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
};

/**
 *
 * @param {{token: string}} requestData
 */
const apiGetDynDns = async requestData => {
  try {
    const { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: requestData.token,
      },
      url: `${DYNDNS_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * @param {string} token
 * @param {string} password
 * @param {string} profileId
 */
const deleteProfile = async (token, password, profileId) => {
  try {
    let { status } = await axios({
      method: 'delete',
      responseType: 'json',
      data: {
        password: password,
      },
      headers: {
        token: token,
      },
      url: `${PROFILE_ENDPOINT}/${profileId}`,
    });

    return status;
  } catch (error) {
    return error.response.status;
  }
};

/**
 * @param {string} domain
 */
const apiClearDomainCache = async domain => {
  try {
    let { status } = await axios({
      method: 'post',
      responseType: 'json',
      data: {
        domain: domain,
      },
      url: `${SERVER_URL}/clear-cache`,
    });

    return status;
  } catch (error) {
    return error.response.status;
  }
};

export {
  apiFetchProfile,
  apiUpdateProfile,
  apiFetchDomains,
  apiAddDomain,
  apiRemoveDomain,
  apiUpdateDomain,
  apiFetchChildren,
  apiAddChild,
  apiRemoveChild,
  apiUpdateChild,
  apiResetPasswordRequest,
  apiResetPasswordConfirm,
  apiGetDynDns,
  deleteProfile,
  apiClearDomainCache,
  SERVER_URL,
  SAFESEARCH_ENDPOINT,
  PORTAL_ENDPOINT,
  LOGFILE_ENDPOINT,
  adminApiSafeSearchList as apiSafeSearchList,
  PROFILE_ENDPOINT as USER_ENDPOINT,
  USERQUOTA_ENDPOINT
};
