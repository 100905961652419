import React, { createContext, Component } from 'react';
import { getUser } from '../util/auth';
import { apiFetchProfile } from '../util/api';

const defaultState = {
  user: undefined,
  token: undefined,
  changeUserState: () => {},
  changeToken: () => {},
};

const AppContext = createContext(defaultState);

class AppProvider extends Component {
  state = {
    user: undefined,
    token: undefined,
  };

  changeUserState = user => {
    this.setState({ user });
  };

  changeToken = token => {
    this.setState({ token });
  };

  async componentDidMount() {
    if (this.state.user === undefined && getUser() !== undefined && getUser() !== null) {
      const { username, token } = getUser();
      this.changeToken(token);
      this.changeUserState(await apiFetchProfile(username, token));
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          user: this.state.user,
          changeUserState: this.changeUserState,
          token: this.state.token,
          changeToken: this.changeToken,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { AppContext, AppProvider };
