// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("./../../../src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-index-jsx": () => import("./../../../src/pages/admin/index.jsx" /* webpackChunkName: "component---src-pages-admin-index-jsx" */),
  "component---src-pages-admin-logfile-jsx": () => import("./../../../src/pages/admin/logfile.jsx" /* webpackChunkName: "component---src-pages-admin-logfile-jsx" */),
  "component---src-pages-admin-portal-jsx": () => import("./../../../src/pages/admin/portal.jsx" /* webpackChunkName: "component---src-pages-admin-portal-jsx" */),
  "component---src-pages-admin-safesearch-jsx": () => import("./../../../src/pages/admin/safesearch.jsx" /* webpackChunkName: "component---src-pages-admin-safesearch-jsx" */),
  "component---src-pages-admin-user-jsx": () => import("./../../../src/pages/admin/user.jsx" /* webpackChunkName: "component---src-pages-admin-user-jsx" */),
  "component---src-pages-admin-user-quota-jsx": () => import("./../../../src/pages/admin/userQuota.jsx" /* webpackChunkName: "component---src-pages-admin-user-quota-jsx" */),
  "component---src-pages-clear-cache-jsx": () => import("./../../../src/pages/clear-cache.jsx" /* webpackChunkName: "component---src-pages-clear-cache-jsx" */),
  "component---src-pages-dashboard-blacklist-jsx": () => import("./../../../src/pages/dashboard/blacklist.jsx" /* webpackChunkName: "component---src-pages-dashboard-blacklist-jsx" */),
  "component---src-pages-dashboard-children-jsx": () => import("./../../../src/pages/dashboard/children.jsx" /* webpackChunkName: "component---src-pages-dashboard-children-jsx" */),
  "component---src-pages-dashboard-dyndns-jsx": () => import("./../../../src/pages/dashboard/dyndns.jsx" /* webpackChunkName: "component---src-pages-dashboard-dyndns-jsx" */),
  "component---src-pages-dashboard-profile-jsx": () => import("./../../../src/pages/dashboard/profile.jsx" /* webpackChunkName: "component---src-pages-dashboard-profile-jsx" */),
  "component---src-pages-dashboard-settings-jsx": () => import("./../../../src/pages/dashboard/settings.jsx" /* webpackChunkName: "component---src-pages-dashboard-settings-jsx" */),
  "component---src-pages-dashboard-whitelist-jsx": () => import("./../../../src/pages/dashboard/whitelist.jsx" /* webpackChunkName: "component---src-pages-dashboard-whitelist-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-password-reset-jsx": () => import("./../../../src/pages/password-reset.jsx" /* webpackChunkName: "component---src-pages-password-reset-jsx" */)
}

