import axios from 'axios';

import { USER_ENDPOINT, SAFESEARCH_ENDPOINT, PORTAL_ENDPOINT, LOGFILE_ENDPOINT,USERQUOTA_ENDPOINT } from './api';

/**
 * @typedef {{ fieldname: string, direction: 'ASC' | 'DESC' }} FieldSorting
 */

/**
 * Get the user profile
 *
 * @param {string} token
 * @param {number} limit
 * @param {number} offset
 * @param {Array<FieldSorting>} [sorting]
 */
const adminApiUserlist = async (token, limit, offset, sorting = [], search = '', type = '', quota = '', active = '') => {
  const searchParams = new URLSearchParams();
  if (sorting.length > 0) {
    searchParams.set('sorting', JSON.stringify(sorting));
  }
  searchParams.set('limit', limit);
  searchParams.set('offset', offset);
  searchParams.set('search', search);
  searchParams.set('type', type);
  searchParams.set('quotaStateType', quota);
  searchParams.set('active', active);
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${USER_ENDPOINT}/?${searchParams.toString()}`,
    });

    if (status === 200) {
      console.log(data);
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiUserUpdate = async (token, updatedUser) => {
  try {
    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      data: updatedUser,
      headers: {
        token: token,
      },
      url: `${USER_ENDPOINT}/${updatedUser.id}`,
    });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchList = async token => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${SAFESEARCH_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchAdd = async (token, sendData) => {
  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      headers: {
        token: token,
      },
      data: sendData,
      url: `${SAFESEARCH_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchUpdate = async (token, sendData) => {
  try {
    const { id, ...rest } = sendData;

    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      headers: {
        token: token,
      },
      data: rest,
      url: `${SAFESEARCH_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiSafeSearchDelete = async (token, id) => {
  try {
    let { status, data } = await axios({
      method: 'delete',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${SAFESEARCH_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalList = async token => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${PORTAL_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalAdd = async (token, sendData) => {
  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      headers: {
        token: token,
      },
      data: sendData,
      url: `${PORTAL_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalUpdate = async (token, sendData) => {
  try {
    const { id, ...rest } = sendData;

    let { status, data } = await axios({
      method: 'patch',
      responseType: 'json',
      headers: {
        token: token,
      },
      data: rest,
      url: `${PORTAL_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiPortalDelete = async (token, id) => {
  try {
    let { status, data } = await axios({
      method: 'delete',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${PORTAL_ENDPOINT}/${id}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiLogfile = async (token) => {
  try {
    let { status, data } = await axios({
      method: 'get',
      responseType: 'json',
      headers: {
        token: token,
      },
      url: `${LOGFILE_ENDPOINT}`,
    });

    if (status === 200) {
      return data;
    }

    if (status === 401) {
    }
  } catch (error) {
    return undefined;
  }
};

const adminApiUserQuota = async (token, sendData) => {
  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      headers: {
        token: token,
      },
      data: sendData,
      url: `${USERQUOTA_ENDPOINT}`,
    });

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Handle 401 error
    } else if (error.response && error.response.status === 404) {
      throw new Error('404');
    } else {
      throw error;
    }
  }
};

export {
  adminApiUserlist,
  adminApiUserUpdate,
  adminApiSafeSearchList,
  adminApiSafeSearchAdd,
  adminApiSafeSearchDelete,
  adminApiSafeSearchUpdate,
  adminApiPortalList,
  adminApiPortalAdd,
  adminApiPortalDelete,
  adminApiPortalUpdate,
  adminApiLogfile,
  adminApiUserQuota
};
